@media (max-width: 1919.98px) {
.full_container {max-width: 100%;}
.home_banner_block{ height:46rem}
.banner_content{ margin-left:0;}
.about_sec{ height:36.64rem;}
.about_content{ margin:3rem 0rem;}

.banner_service{ height:24rem;}
}

@media (max-width: 1599.98px) {
.home_banner_block{ height:41rem}
.about_sec{ height:31.64rem;}
.banner_service{ height:21rem;}
}

@media screen and (max-width: 1439.98px) {
	.banner_service{ height:20rem;}

}


@media (max-width: 1365.98px) {
.about_content{ margin:0 auto;}
.about_sec {height:26.64rem;}	
.banner_service{ height:18rem;}
}




@media (max-width: 1199.98px) {
	.about_sec .heading_sec h2 {font-size: 30px;}
html {font-size: 12px;}
.home_banner_block{ height:30rem;}
.banner_btn{ bottom:0;}
.banner_content{ margin-left:0;}
.banner_title{ font-size:3rem}
.about_content ul {margin: 20px 0;}
.about_content ul li  img { max-width: 60px; }
.about_content ul li h4 {font-size: 18px;}
.about_content h3 {
    font-size: 25px;
}
.about_content{ margin:0;}
.about_sec{ padding:4rem 0rem;}
.about_img{ width:40%;}
.about_sec{ height:29rem}
.service_sec{ padding:2rem 0px 4rem;}
.work_sec{ padding:2rem 0rem 4rem;}
.testimonial_sec{ padding:3rem 0 4rem;}
footer{ padding:7rem 0rem 2rem;}
.service_sec .box a h4{ font-size:2rem;}
.banner_service{ height:16rem;}
.inquiry_form .title{ padding-bottom:1rem; font-size:1.40rem;}
	
}


@media (max-width: 991.98px) {
	.work_sec video {
    margin: 0 auto;
    width: 100%;
    height: auto;
}
	.about_sec {
		padding: 20px 0;
	}
	.about_sec .heading_sec h2 {
		font-size: 25px;
		line-height: 30px;
	}
	.about_content ul li  img {
		max-width: 50px;
	}
	.about_content ul li h4 {
		font-size: 15px;
		margin: 5px 0 0;
	}
	.about_content ul {
		margin: 10px 0;
	}
	.about_content h3 {
		font-size: 20px;
	}
	.star {
		margin-top: 15px;
		font-size: 25px;
	}
	.testimonial_sec .test_slider p {
		color: #000;
		font-size: 14px;
		letter-spacing: 1px;
	}
	.testimonial_sec .test_slider h4 {font-size: 18px;}
	.faq_content {
		margin-top: 20px;
	}
.video_box::before{ display:none;}
.testimonial_sec .slick-next{ right:0;}
.testimonial_sec .slick-prev{ left:0;}
.service_sec .box a h4{ font-size:1.50rem;}

.heading_sec h2{ font-size:2rem}
.service_sec .box .title{ font-size:1.20rem;}
.logo img {width: 12rem;}

.navbar-collapse {width: 100%;-ms-flex-wrap: wrap;flex-wrap: wrap;position: static;
-webkit-transform: translate(0%, 0%);transform: translate(0%, 0%);background-color: #ffffff;padding: 1rem 0rem;box-shadow: 4px 4px 4px -6px rgba(150, 145, 145, 0.1);
}

.navigation .navbar-nav .nav-link {padding:0.4rem 0rem;font-size: 1.18rem;}
.home_banner_block {padding-top:5rem;height:24rem;}
.home_banner_block:before{width: 100%;height: 100%;position: absolute;top: 0%;left: 0%;background-color: rgba(0,0,0,0.5);content: '';z-index: 1;}
.banner_btn .lead_text{ width:100%; text-align:center;}
.banner_btn{ position:inherit; left:inherit; top:inherit; display:flex; flex-wrap:wrap; justify-content:center;}
.banner_content {display: flex;align-items: center;justify-content: center;flex-wrap: wrap;z-index:1;text-align:center;}

.navbar-expand-md > .container {-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
header nav.navbar {padding: 1.5rem 0rem;}
.navigation.fixed-top .navbar-expand-md .navbar-nav {margin-top: 1.5rem !important;}

header .collapse:not(.show) {display: none !important;}

.navbar-expand-md .navbar-toggler {display: block !important;}
.navbar-expand-md .navbar-collapse {width: 100%;-ms-flex-wrap: wrap;flex-wrap: wrap;position: static;-webkit-transform: translate(0%, 0%);transform: translate(0%, 0%);}
.navbar-expand-md .navbar-nav {width: 100%;background-color: #ffffff;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;padding: 0rem 1.07rem;}

.navigation .navbar-nav li {padding: .5rem 0rem;}
.navigation .navbar-nav li a::before {display: none;}

.slick-prev:before,
.slick-next:before {font-size: 2.5rem;}

.about_sec{ height:23rem;}
.about_img{ width:42%;}
.about_sec .about_content p{ font-size:1.18rem;}
	
.banner_service {height: 13rem;}
   
}

@media (min-width:768px){

}

@media (max-width: 767.98px) {
.home_banner_block{ height:auto; background-position:right -0.2rem center;}
.banner_title{ font-size:2rem;}
.banner-text{ font-size:1rem; width:25rem;}
.lead_text{ font-size:1.18rem}


.testimonial_sec .test_slider ul li{ margin-bottom:1rem; font-size:1.14rem;}
.testimonial_sec .slick-list:after{ display:none;}

.app-stripe,
.work_sec,
.service_sec,
.about_sec{padding:2rem 0rem;}
.heading_sec h2{ font-size:1.71rem;}
footer{ background-image:none; background-color:#373737; padding:2rem 0rem;}
footer .footer-info{ max-width:auto;}

.about_content{ max-width:100%;}	
.about_img{ position: inherit; width:100%; display:none;}
.about_sec{ height:auto;}

.testimonial_sec{ padding:2rem 0rem 3rem;}

.container-fluid {padding: 0 15px;}


.logo img {width: 8rem}
.slick-dots li.slick-active button:before {color:#ed1d24;}
.slick-dots li button:before {opacity: 1;color: #ef4343; }
.slick-dots li button:before {font-size: 12px;}
.slick-dots {bottom: -35px;}
.inquiry_sec{ padding:1rem;}
.inquiry_form{ padding:1rem;}
.slick-dots li {margin: 0 2px;}
.banner_service{ height:10rem;}
.banner_service{ background-position:right -0.5rem center;}
.inquiry_form .form-control{ height:3.60rem;}
.inquiry_form textarea.form-control{ height:6rem;} 
.privacy_sec .privacy_banner{width: 100%;text-align: center;}
.privacy_sec .privacy_content p{ margin-bottom:1rem;}
.privacy_sec{ padding:2rem 0rem}
}


@media (max-width: 575.98px) {
	.navigation .navbar {padding-left: 15px;padding-right: 15px;}
	.navigation.fixed-top nav {padding: 0.5rem 15px;}
	.banner_service{ height:8rem;}

}

@media (max-width: 479.98px) {
}

@media (max-width:379.98px){

}