 @font-face {
  font-family: 'sf_ui_displayblack';
  src: url('../fonts/sf-ui-display-black-webfont.woff') format('woff2'),
       url('../fonts/sf-ui-display-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
} 

@font-face {
  font-family: 'sf_ui_displaybold';
  src: url('../fonts/sf-ui-display-bold-webfont.woff2') format('woff2'),
       url('../fonts/sf-ui-display-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sf_ui_displaylight';
  src: url('../fonts/sf-ui-display-light-webfont.woff2') format('woff2'),
       url('../fonts/sf-ui-display-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
} 

@font-face {
  font-family: 'sf_ui_displayregular';
  src: url('../fonts/sf-ui-display-regular-webfont.woff2') format('woff2'),
       url('../fonts/sf-ui-display-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal; 
} 

html{  font-family: 'sf_ui_displayregular'; font-size: 13px;}
body{  font-family: 'sf_ui_displayregular'; font-size: 1.28rem; color: #353535; line-height:normal; position: relative;}

::-webkit-scrollbar{ width: 8px; height: 8px; box-shadow: inset 0px 0px 6px rgba(0,0,0,0);  -webkit-border-radius: 8px; border-radius: 8px;}
/*add a shadow to the scrollbar here*/
::-webkit-scrollbar-track{ box-shadow: inset 0px 0px 0px rgba(0,0,0,0);  -webkit-border-radius: 8px; border-radius: 8px;}
/*this is the little scrolly dealio in the bar*/ 
::-webkit-scrollbar-thumb{ border-radius: 8px; background-color: rgba(0,0,0,0.2); height: 3px;}
/*nobody needs this little scrollbar corner, I mean really, get rid of it haha*/  
::-webkit-scrollbar-corner{ display: none; height: 0px; width: 0px;}

img { max-width: 100%; display: inline-block;}
button, input, textarea, select {  font-family: 'sf_ui_displayregular'; opacity: 1; letter-spacing: 0.0143rem;}
button:focus, input:focus, textarea:focus, select:focus{ outline: none;}
ul, li { padding: 0px; margin: 0px; list-style: none;}
a { display: inline-block; -webkit-transition: all 0.5s ease-out; transition: all 0.5s ease-out; color: #dd2a2a;}
a:hover, a:focus { outline: inherit; text-decoration: none;}
a:hover { color: #2e56bf;}
h1, h2, h3, h4, h5, h6 {  font-family: 'sf_ui_displayregular'; line-height: normal; padding: 0px 0px; margin: 0px 0px;}

section::after{ display: block; clear: both; content: '';}

.full_container{ max-width: 1920px; width: 100%; overflow: hidden; position: relative; margin: 0rem auto;}

.form-control::-webkit-input-placeholder{ color:#727272; opacity:1;}
.form-control::-moz-placeholder{ color:#727272; opacity:1;}
.form-control::-ms-input-placeholder{ color:#727272; opacity:1;}
.form-control:-ms-input-placeholder{ color:#727272; opacity:1;}
.form-control::placeholder{ color:#727272; opacity:1;}



.logo{ display: -webkit-inline-box; display: -ms-inline-flexbox; display: inline-flex; -webkit-box-align: center; -ms-flex-align: center; padding:0;}
.logo img {width:11.71rem; display: inline-block; -webkit-transition: all 0.5s ease-out; transition: all 0.5s ease-out;}

.navigation{width: 100%; /* position: absolute; top: 0%; left: 0%;z-index: 9;*/} 
.navigation.fixed-top{ width: 100%; position: fixed; top: 0%; -webkit-animation: slide-down 0.7s; animation: slide-down 0.7s; -webkit-box-shadow: 0px 0.071rem 0.71rem 0px rgba(0, 0, 0, 0.1); box-shadow: 0px 0.071rem 0.71rem 0px rgba(0, 0, 0, 0.2); z-index: 9999; background: #fff;}
.navbar-expand-lg{ -ms-flex-flow: wrap; flex-flow: wrap; padding:1.14rem 0rem;}
.navigation .navbar-nav .nav-link{display: -webkit-inline-box; display: -ms-inline-flexbox; display: inline-flex; position: relative; padding:0.2rem 1.2rem; font-size:1.21rem; color:#000000; font-family: 'sf_ui_displaybold'; position:relative;}
.navigation .navbar-nav .nav-link::before {width: 2.14rem; height: 2px; background-color:#ef4343; position: absolute; bottom: 0%; left:1rem;  overflow: hidden; -webkit-transform: scale(0); transform: scale(0); -webkit-transition: all 0.5s ease-out; transition: all 0.5s ease-out; content: '';}
.navigation .navbar-nav .nav-link:hover::before{ -webkit-transform: scale(0); transform: scale(0);}
.navigation .navbar-nav .nav-link:hover{color:#ed1d24;}
.navigation .navbar-nav .nav-link:last-child{ padding-right:0;}

.navigation .navbar-toggler{width: 40px; height: 35px; line-height: 0; color: #ffffff;border-color:#ed1d24;background-color: transparent;padding: 0px 0px;margin: 0rem 0rem 0rem auto;
}


 .navbar-light .navbar-toggler-icon{  background-image:url("../images/gird.svg");  background-repeat: no-repeat; background-position:left center;} 


.navigation.fixed-top {
    width: 100%;
    position: fixed;
    top: 0%;
    animation: slide-down 0.7s;
    box-shadow: 8px 0px 28px 0px rgba(150, 145, 145, 0.3);
    z-index: 9;
    background: #fff;
}
.navigation.fixed-top .navbar-expand-lg{padding: 0.5rem 15px;}

.inner_header{box-shadow:0px 1px 2px 0px rgba(0, 0, 0, 0.30);}

@keyframes slide-down{
0%{ opacity: 1; -webkit-transform: translateY(-100%); transform: translateY(-100%);}
100%{ opacity: 1; -webkit-transform: translateY(0); transform: translateY(0);} 
}



.home_banner_block{ padding-top:10rem; position:relative; padding-bottom:2rem; background-image: url(../images/banner.jpg);background-size:cover; background-position:left center; background-repeat: no-repeat; height:54rem;}
.banner_content {margin-left:-27rem; position: relative; height: 100%;}
.banner_title{ color:#fff; font-size:3.14rem; font-family: 'sf_ui_displaybold'; text-shadow: 1px 2px 0px rgba(0, 0, 0, 0.15);}
.banner_title .color-red{ color:#ed1d24;}
.banner-text{color:#ffffff;font-size: 1.28rem; font-family: 'sf_ui_displaybold'; background-color: #ed1d24;height: 3.21rem;width: 31.28rem;display: flex;align-items: center;justify-content: center; border-radius:2px; box-shadow:0px 1px 2px 0px rgba(0, 0, 0, 0.30); }
.banner_btn{position:absolute; bottom:6rem; left:0rem;} 
.lead_text{ color:#fff; font-size:1.28rem;}
.lead_text strong{ font-family: 'sf_ui_displaybold';}


.heading_sec h2{ font-family: 'sf_ui_displayblack';font-size: 2.42rem;}

 
.about_sec {
     padding: 40px 0 30px;
     background-color: #ed1d24;
     position: relative;
     height: auto;
     overflow: hidden;
 }
 .about_sec .about_content p{font-size: 1.35rem;color: #fff;}
 .about_content ul {display: flex; justify-content: space-between;     margin: 50px 0;}
 .about_content ul li {width: 33.33%; text-align: center; padding: 0 10px;}
 .about_content ul li h4 {font-size: 28px; margin: 10px 0 0; font-family: 'sf_ui_displayregular'; color: #fff;}
 .about_content h3 span, .about_content ul li h4 span { font-family: 'sf_ui_displayblack'; }
 .about_content h3 {font-size: 40px; }

 .about_right{position:inherit;} 
 .about_img{position: absolute; right:0px; top: 0; width:34%; text-align:right;}
 .about_img img{ width:100%;} 
 

  /* service_sec */
 
  .service_sec{padding: 2.85rem 0px 5rem;}
 
 .service_list{ margin:-0.4rem;}
 .service_list .no-gutters>.col, .no-gutters>[class*=col-]{ overflow:hidden;}
 .service_sec .box {position: relative;overflow: hidden;margin:0.4rem;height: 100%;}
 .service_sec .box .title {font-family: 'sf_ui_displayblack';font-size: 1.42rem;color: #fff;position: absolute;left:1rem;bottom:1.5rem;}
 .service_sec .box img {-webkit-transform: scale(1);  transform: scale(1); -webkit-transition: all 0.5s ease-out; transition: all 0.5s ease-out;}
 .service_sec .box:hover img{ -webkit-transform: scale(1.2);transform: scale(1.2);}
 
 .service_sec .box a{ background-color:#ed1d24; height:100%; color:#fff; justify-content:center;}
 .service_sec .box a h4{width:8rem;font-family: 'sf_ui_displayblack';font-size: 2.28rem;}
 .service_sec .box a i{font-size: 1.5rem;} 
 
 .work_sec{background-color: #f1f4fb;padding: 2.35rem 0rem 4.64rem;}
 .video_box{ position:relative; max-width:725px; margin:0 auto;}
 .video_box::before{ position:absolute; content:""; right:-84px; top:-80px;  background-image: url(../images/dotted.png);background-repeat: no-repeat;width:8.42rem;height:8.92rem;}
  
  /* work_sec */
 
 
  /* test_sec */
 
 .testimonial_sec{padding:3.21rem 0rem 4.64rem;}
 .testimonial_sec .test_slider ul li{font-family: 'sf_ui_displaylight';font-size: 1.28rem;margin-bottom: 2.71rem; color: #636363;}
 .testimonial_sec .test_footer h4{font-family: 'sf_ui_displaybold';font-size: 1.28rem;color: #4b4b4b;}
 .testimonial_sec .test_footer span{font-family: 'sf_ui_displaylight';font-size: 1rem;}
 .testimonial_sec .slick-prev:before{ content: "\2190"!important;}
 .testimonial_sec .slick-prev:before,  
 .testimonial_sec .slick-next:before{font-family: FontAwesome!important;font-size: 36px!important;color: #000;}
 .slick-prev:hover:before, 
 .slick-next:hover:before{ color:#ed1d24;}
 .testimonial_sec .slick-prev {left:-9%;z-index: 999;}
 .testimonial_sec .slick-next {right:-9%;}
 .testimonial_sec .test_slider{ padding:0rem 2rem;}
 .testimonial_sec .slick-list {position: relative;}
 .testimonial_sec .slick-list:after {width: 1px; z-index:-1; background: #00000047; height:100%; position: absolute; content:""; left:50%; top:0px;}
  


 .banner_service{ margin-top: -1rem; height:28rem; background-image:url("../images/service_banner.png"); background-size:cover; background-position:center left; background-repeat: no-repeat;}     
 .inquiry_sec{ background-color:#d3d3d3; padding:4.28rem 0rem;}
 .inquiry_form{ border:4px solid #ed1d24; box-shadow:0px 1px 2px 0px rgba(0, 0, 0, 0.30); padding:2.7rem;}
 .inquiry_form .title{ font-size:1.85rem; color:#000; font-family: 'sf_ui_displaybold'; padding-bottom:2.85rem;}
 .inquiry_form .form-control{ height:4.21rem; border:1px solid #dbdbdb; color:#727272; font-size:1.14rem;  font-family: 'sf_ui_displaylight'; border-radius:0;}
 .inquiry_form .btn_large{ height:4.21rem;}
 .inquiry_form textarea.form-control{ height:9rem; padding-top:1rem;}

 .custom-select{ background-image:url("../images/custom_select.svg"); background-size:.75rem;}  



 .inner_heading span{ display: inline-block; color: #ed1d24; font-size: 1rem;text-transform: uppercase;font-family: 'sf_ui_displaybold';}
.inner_heading h2{font-size: 1.85rem;font-family: 'sf_ui_displaybold';}
.inner_heading:before,.inner_heading:after {position: absolute; content: ""; background-color: #ff0009; width: 0.28rem;height: 4rem; left: 0px; top: 4px;}
.inner_heading:after{ background-color: #757575; height: 2.14rem;}
.inner_heading{ position: relative;}

/* privacy_sec */

.privacy_sec{padding: 2.85rem 0px 5rem;}
.privacy_sec .privacy_content p {font-family: 'sf_ui_displaylight'; font-size: 1.14rem; color: #636363; line-height: 1.6; margin-bottom: 2.70rem;}
.privacy_sec .privacy_banner{box-shadow: 8px 0px 28px 0px rgb(150 145 145 / 30%); padding: 5rem 1rem 0rem 2rem;}
/* privacy_sec */




.app-stripe{ background-image: url("../images/app-stripe.jpg"); background-size:100% 100%; background-position:0 0; background-repeat: no-repeat; padding:2.85rem 0rem; }

footer{background-image:url("../images/footer_bg.png"); background-repeat:no-repeat; background-size:cover; background-position:center center; padding:13rem 0 2rem;}
.footer_logo{ min-width:13.64rem;}
footer .title{ font-size:1.14rem; color:#fff;  font-family: 'sf_ui_displaybold'; text-transform: uppercase; }
footer p{ color:#fff; font-size:1.07rem; font-family: 'sf_ui_displaylight';}

footer .social-link li{padding-right:0.6rem;}
footer .social-link li a{width:2.80rem; height:2.80rem; border:1px solid #fff; align-items: center; justify-content: center; display:flex;}
footer .social-link li a:hover{ background-color:#ed1d24; border-color:#ed1d24;}
footer .social-link li a i{ font-size:1.14rem; color:#fff;}
footer .footer-info{ max-width:200px;}
footer address{ color:#fff; font-size:1.14rem; font-family: 'sf_ui_displaylight';}
footer address i{ font-size:1.5rem;} 


.btn{ font-family: 'sf_ui_displaybold'; font-size:1.42rem; color:#fff; border-color:#ed1d24; -webkit-transition: all 0.5s ease-out; transition: all 0.5s ease-out; background:#ed1d24;}
.btn:hover{border-color:#ed1d24; background-color:#fff; color:#ed1d24;}

.text-dark {color: #0a0a0a !important;}
.about_sec .heading_sec h2 {
    font-size: 45px;
    font-weight: 500;
    font-family: 'sf_ui_displayregular';
}
.about_sec .heading_sec h2 span { font-family: 'sf_ui_displaybold'; }
.testimonial_sec .test_slider h4 { color: #000; font-size: 18px; text-transform: uppercase; font-family: 'sf_ui_displaybold';  margin-bottom: 20px; }
.testimonial_sec .test_slider p {color: #000; font-size: 16px; letter-spacing: 1px;}
.testimonial_sec .test_slider p  span {color: #de0000;}
.star {
     margin-top: 40px;
     color: #de0000;
     font-size: 42px;
 }
 .star i {margin-right: 5px;}
 .testimonial_sec .test_slider h6 {color: #000000; font-size: 13px; }
 .copyright  { max-width: 475px; margin:40px auto 0; }
 .faq_content {margin-top: 45px;}
 .faq_content h3 {color: #636363; font-size: 16px; font-family: 'sf_ui_displaybold'; margin-bottom: 10px; }
 .faq_content ul {margin-bottom: 25px;}
 .faq_content ul li {
     color: #636363;
     font-size: 15px;
     margin-bottom: 5px;
 }